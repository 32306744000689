body {
  margin: 8px;
  padding: 0;
  padding: 60px 0 60px;
  background: #f5f5f5;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#toolbar-title {
  flex-grow: 1;
}

#add-key {
  position: fixed;
  right: 5vw;
  bottom: 5vw;
  z-index: 100;
}

.MuiStepper-root {
  padding: 10px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
